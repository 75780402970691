import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"


const Buttonblock = ({ text, link, description }) => {

  let button_url
  let pattern = /^\/{1}[^/]/i

  if (pattern.test(link)) {
    button_url = <Link className="text-uppercase font-weight-bold text-decoration-none h5 px-4 py-1" style={{backgroundColor: '#fc5c00', color: '#700666', boxShadow: '0 2px 8px 0 #fff'}} to={link} >{text}</Link>
  } else {
    button_url = <a className="text-uppercase font-weight-bold text-decoration-none h5 px-4 py-1" style={{backgroundColor: '#fc5c00', color: '#700666', boxShadow: '0 2px 8px 0 #fff'}} href={link} rel="noopener noreferrer" target="_blank" >{text}</a>
  }

  return (
    <div className="button-block flex-grow-1 mb-0 py-4 px-md-5 px-3 mx-auto text-center border-right border-bottom border-white h5" style={{width: '456px', backgroundColor: '#002342'}}>
      <div className="button mt-3 mb-4">
        { button_url }
      </div>
      <div className='description text-white pt-2 small'>{description}</div>
    </div>
  )
}

Buttonblock.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  description: PropTypes.string,
}

Buttonblock.defaultProps = {
  text: ``,
  link: ``,
  description: ``,
}

export default Buttonblock
