import React from "react"
import PropTypes from "prop-types"
//import './buttonblocks.scss'


const ButtonBlocks = ({ children }) => {
  return (
    <div className="button-blocks d-flex flex-wrap justify-content-between w-100">
      {children}
    </div>
  )
}

ButtonBlocks.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ButtonBlocks
